<script setup>
import VaildationInputListVue from '@/components/ui/VaildationInputList.vue'
import { findId } from '@/api/auth'
import { regEmail } from '@/util/regex'
import { ref } from 'vue'

const vaildation = ref(null)
const tryFindUserId = ref(false)
const foundUserId = ref('')

const validationInputs = [
  {
    type: 'text',
    placeholder: '이메일',
    isValidFunc: ({ value }) => regEmail.test(value),
    vaildText: '',
    invalidText: '이메일형식으로 입력해주십시오'
  }
]

const formSubmitHandler = async (e) => {
  if (!vaildation.value.vaildate()) return

  const [email] = vaildation.value.submitData
  const result = await findId(email.value)

  tryFindUserId.value = true

  // 가입된 email이 아닐때
  if (result.code && result.code === 10002) return

  return (foundUserId.value = result.email)
}
</script>

<template>
  <article class="wf__login-form">
    <div class="wf__login-form-title-wrapper">
      <h4 class="wf__login-form-title">아이디찾기</h4>
      <p class="wf__login-form-description">회원가입시 기입한 이메일을 입력해주십시오</p>
    </div>

    <form class="wf__login-form-body" @submit.prevent="formSubmitHandler">
      <section class="wf__login-form-input-wrapper">
        <VaildationInputListVue ref="vaildation" :items="validationInputs" />

        <div v-if="tryFindUserId">
          <span v-if="!foundUserId"> 해당 이메일로 가입한 사용자가 없습니다. </span>
          <span v-if="foundUserId">사용자 ID: {{ foundUserId }}</span>
        </div>
      </section>

      <button v-if="!foundUserId" class="wf__login-form-submit" type="submit">아이디 찾기</button>
    </form>
  </article>
</template>

<style scoped>
.wf__login-form {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.wf__login-form,
.wf__login-form-title-wrapper,
.wf__login-form-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.wf__login-form-title-wrapper {
  gap: 0.5rem;
}
.wf__login-form-title {
  font-size: 36px;
  font-weight: bold;
}
.wf__login-form-subtitle {
  font-size: 24px;
  color: var(--wf-secondary-color);
}
.wf__login-form-description {
  font-size: 16px;
  color: var(--wf-gray-color);
}

.wf__login-form-body {
  align-items: center;
  width: 100%;
  max-width: 468px;

  row-gap: 3rem;
}
.wf__login-form-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.wf__login-form-redirects {
  display: flex;
  gap: 0.5em;
}

.wf__login-form-submit {
  outline: none;
  border: none;
  color: #fff;
  background-color: var(--wf-primary-color);
  border-radius: 0.25em;
  letter-spacing: 0.5px;
  font-weight: 400;
  padding: 0.35em 1em;
}
.wf__login-form-submit:hover {
  opacity: 0.8;
}
</style>
